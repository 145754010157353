import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type BillingAccount = Node & {
  __typename?: 'BillingAccount';
  createdAt: Scalars['String'];
  createdBy: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  plan: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['String'];
  updatedBy: Scalars['String'];
};

export type BillingAccountConnection = {
  __typename?: 'BillingAccountConnection';
  edges?: Maybe<Array<Maybe<BillingAccountEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type BillingAccountEdge = {
  __typename?: 'BillingAccountEdge';
  cursor: Scalars['String'];
  node?: Maybe<BillingAccount>;
};

export type CreateDiagramInput = {
  name: Scalars['String'];
  projectId: Scalars['String'];
  type: Scalars['String'];
};

export type CreateDiagramPayload = {
  __typename?: 'CreateDiagramPayload';
  diagram?: Maybe<Diagram>;
  error?: Maybe<MutationValidationError>;
};

export type CreateProjectInput = {
  name: Scalars['String'];
};

export type CreateProjectPayload = {
  __typename?: 'CreateProjectPayload';
  error?: Maybe<MutationValidationError>;
  project?: Maybe<Project>;
};

export type Diagram = Node & {
  __typename?: 'Diagram';
  content?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  createdBy: Scalars['String'];
  embedImageUrl: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  project: Project;
  shareImageUrl: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['String'];
  updatedBy: Scalars['String'];
};

export type DiagramConnection = {
  __typename?: 'DiagramConnection';
  edges?: Maybe<Array<Maybe<DiagramEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type DiagramEdge = {
  __typename?: 'DiagramEdge';
  cursor: Scalars['String'];
  node?: Maybe<Diagram>;
};

export type FieldValidationError = {
  __typename?: 'FieldValidationError';
  errors: Array<Scalars['String']>;
  field: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  _empty?: Maybe<Scalars['String']>;
  createDiagram?: Maybe<CreateDiagramPayload>;
  createProject?: Maybe<CreateProjectPayload>;
  sendMessage?: Maybe<SendMessagePayload>;
  signUp?: Maybe<SignUpPayload>;
  updateDiagram?: Maybe<UpdateDiagramPayload>;
  updatePassword?: Maybe<UpdatePasswordPayload>;
  updatePersonalProfile?: Maybe<UpdatePersonalProfilePayload>;
  updateProfileSettings?: Maybe<UpdateProfileSettingsPayload>;
  updateProject?: Maybe<UpdateProjectPayload>;
};


export type MutationCreateDiagramArgs = {
  input: CreateDiagramInput;
};


export type MutationCreateProjectArgs = {
  input: CreateProjectInput;
};


export type MutationSendMessageArgs = {
  input: SendMessageInput;
};


export type MutationSignUpArgs = {
  input: SignUpInput;
};


export type MutationUpdateDiagramArgs = {
  input: UpdateDiagramInput;
};


export type MutationUpdatePasswordArgs = {
  input: UpdatePasswordInput;
};


export type MutationUpdatePersonalProfileArgs = {
  input: UpdatePersonalProfileInput;
};


export type MutationUpdateProfileSettingsArgs = {
  input: UpdateProfileSettingsInput;
};


export type MutationUpdateProjectArgs = {
  input: UpdateProjectInput;
};

export type MutationValidationError = {
  __typename?: 'MutationValidationError';
  code?: Maybe<Scalars['String']>;
  details?: Maybe<Array<FieldValidationError>>;
  message: Scalars['String'];
};

export type Node = {
  id: Scalars['ID'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type Project = Node & {
  __typename?: 'Project';
  canCreateDiagram: Scalars['String'];
  createdAt: Scalars['String'];
  diagrams?: Maybe<DiagramConnection>;
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['String'];
};


export type ProjectDiagramsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type ProjectConnection = {
  __typename?: 'ProjectConnection';
  edges?: Maybe<Array<Maybe<ProjectEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type ProjectEdge = {
  __typename?: 'ProjectEdge';
  cursor: Scalars['String'];
  node?: Maybe<Project>;
};

export type Query = {
  __typename?: 'Query';
  _empty?: Maybe<Scalars['String']>;
  node?: Maybe<Node>;
  viewer?: Maybe<Viewer>;
};


export type QueryNodeArgs = {
  id: Scalars['ID'];
};

export type SendMessageInput = {
  message: Scalars['String'];
};

export type SendMessagePayload = {
  __typename?: 'SendMessagePayload';
  error?: Maybe<MutationValidationError>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SignUpInput = {
  accept: Scalars['Boolean'];
  email: Scalars['String'];
  lastName: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
};

export type SignUpPayload = {
  __typename?: 'SignUpPayload';
  error?: Maybe<MutationValidationError>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateDiagramInput = {
  content?: InputMaybe<Scalars['String']>;
  diagramId: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateDiagramPayload = {
  __typename?: 'UpdateDiagramPayload';
  diagram?: Maybe<Diagram>;
  error?: Maybe<MutationValidationError>;
};

export type UpdatePasswordInput = {
  currentPassword?: InputMaybe<Scalars['String']>;
  newPassword?: InputMaybe<Scalars['String']>;
};

export type UpdatePasswordPayload = {
  __typename?: 'UpdatePasswordPayload';
  error?: Maybe<MutationValidationError>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdatePersonalProfileInput = {
  bio?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdatePersonalProfilePayload = {
  __typename?: 'UpdatePersonalProfilePayload';
  error?: Maybe<MutationValidationError>;
  viewer?: Maybe<Viewer>;
};

export type UpdateProfileSettingsInput = {
  addSuppressedNotification?: InputMaybe<Scalars['String']>;
};

export type UpdateProfileSettingsPayload = {
  __typename?: 'UpdateProfileSettingsPayload';
  error?: Maybe<MutationValidationError>;
  viewer?: Maybe<Viewer>;
};

export type UpdateProjectInput = {
  name?: InputMaybe<Scalars['String']>;
  projectId: Scalars['String'];
};

export type UpdateProjectPayload = {
  __typename?: 'UpdateProjectPayload';
  error?: Maybe<MutationValidationError>;
  project?: Maybe<Project>;
};

export type Viewer = {
  __typename?: 'Viewer';
  billingAccount: BillingAccount;
  bio?: Maybe<Scalars['String']>;
  canCreateProject: Scalars['String'];
  email: Scalars['String'];
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  projects?: Maybe<ProjectConnection>;
  suppressedNotifications: Array<Maybe<Scalars['String']>>;
};


export type ViewerProjectsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type Layout_ViewerFragment = { __typename?: 'Viewer', id: string, name: string, email: string, billingAccount: { __typename?: 'BillingAccount', plan: string } };

export type Navigation_ViewerFragment = { __typename?: 'Viewer', name: string, email: string };

export type SidebarLayout_ViewerFragment = { __typename?: 'Viewer', name: string, email: string, id: string, billingAccount: { __typename?: 'BillingAccount', plan: string } };

export type UpdateProfileSettingsMutationVariables = Exact<{
  input: UpdateProfileSettingsInput;
}>;


export type UpdateProfileSettingsMutation = { __typename?: 'Mutation', updateProfileSettings?: { __typename?: 'UpdateProfileSettingsPayload', viewer?: { __typename?: 'Viewer', id: string, suppressedNotifications: Array<string | null> } | null } | null };

export type SuppressableNotificationDialog_ViewerFragment = { __typename?: 'Viewer', suppressedNotifications: Array<string | null> };

export type CreateDiagramMutationVariables = Exact<{
  input: CreateDiagramInput;
}>;


export type CreateDiagramMutation = { __typename?: 'Mutation', createDiagram?: { __typename?: 'CreateDiagramPayload', diagram?: { __typename?: 'Diagram', id: string } | null, error?: { __typename?: 'MutationValidationError', message: string, code?: string | null, details?: Array<{ __typename?: 'FieldValidationError', field: string, errors: Array<string> }> | null } | null } | null };

export type DiagramBrowserTree_ProjectFragment = { __typename?: 'Project', id: string, canCreateDiagram: string };

export type DiagramBrowserTree_DiagramConnectionFragment = { __typename?: 'DiagramConnection', totalCount: number, edges?: Array<{ __typename?: 'DiagramEdge', node?: { __typename?: 'Diagram', id: string, name: string, type: string } | null } | null> | null };

export type UpdateDiagramContentMutationVariables = Exact<{
  input: UpdateDiagramInput;
}>;


export type UpdateDiagramContentMutation = { __typename?: 'Mutation', updateDiagram?: { __typename?: 'UpdateDiagramPayload', diagram?: { __typename?: 'Diagram', id: string, content?: string | null, updatedAt: string, updatedBy: string } | null, error?: { __typename?: 'MutationValidationError', message: string, details?: Array<{ __typename?: 'FieldValidationError', field: string, errors: Array<string> }> | null } | null } | null };

export type DiagramInfo_DiagramFragment = { __typename?: 'Diagram', id: string, name: string, type: string, createdAt: string, createdBy: string, updatedAt: string, updatedBy: string };

export type CreateDiagramTutorial_ViewerFragment = { __typename?: 'Viewer', suppressedNotifications: Array<string | null> };

export type CreateProjectMutationVariables = Exact<{
  input: CreateProjectInput;
}>;


export type CreateProjectMutation = { __typename?: 'Mutation', createProject?: { __typename?: 'CreateProjectPayload', project?: { __typename?: 'Project', id: string } | null, error?: { __typename?: 'MutationValidationError', message: string, code?: string | null, details?: Array<{ __typename?: 'FieldValidationError', field: string, errors: Array<string> }> | null } | null } | null };

export type ProjectCard_ProjectFragment = { __typename?: 'Project', id: string, name: string, updatedAt: string, diagrams?: { __typename?: 'DiagramConnection', totalCount: number } | null };

export type ProjectList_ProjectConnectionFragment = { __typename?: 'ProjectConnection', totalCount: number, edges?: Array<{ __typename?: 'ProjectEdge', node?: { __typename?: 'Project', id: string, name: string, updatedAt: string, diagrams?: { __typename?: 'DiagramConnection', totalCount: number } | null } | null } | null> | null };

export type UpdateProjectMutationVariables = Exact<{
  input: UpdateProjectInput;
}>;


export type UpdateProjectMutation = { __typename?: 'Mutation', updateProject?: { __typename?: 'UpdateProjectPayload', project?: { __typename?: 'Project', id: string, name: string } | null, error?: { __typename?: 'MutationValidationError', message: string, details?: Array<{ __typename?: 'FieldValidationError', field: string, errors: Array<string> }> | null } | null } | null };

export type UpdateProject_ProjectFragment = { __typename?: 'Project', id: string, name: string };

export type UpdateProject_ViewerFragment = { __typename?: 'Viewer', name: string, lastName?: string | null, email: string };

export type SendMessageMutationVariables = Exact<{
  input: SendMessageInput;
}>;


export type SendMessageMutation = { __typename?: 'Mutation', sendMessage?: { __typename?: 'SendMessagePayload', success?: boolean | null, error?: { __typename?: 'MutationValidationError', message: string, details?: Array<{ __typename?: 'FieldValidationError', field: string, errors: Array<string> }> | null } | null } | null };

export type UpdatePasswordMutationVariables = Exact<{
  input: UpdatePasswordInput;
}>;


export type UpdatePasswordMutation = { __typename?: 'Mutation', updatePassword?: { __typename?: 'UpdatePasswordPayload', success?: boolean | null, error?: { __typename?: 'MutationValidationError', message: string, details?: Array<{ __typename?: 'FieldValidationError', field: string, errors: Array<string> }> | null } | null } | null };

export type UpdatePersonalProfileMutationVariables = Exact<{
  input: UpdatePersonalProfileInput;
}>;


export type UpdatePersonalProfileMutation = { __typename?: 'Mutation', updatePersonalProfile?: { __typename?: 'UpdatePersonalProfilePayload', viewer?: { __typename?: 'Viewer', name: string, lastName?: string | null, email: string, bio?: string | null } | null, error?: { __typename?: 'MutationValidationError', message: string, details?: Array<{ __typename?: 'FieldValidationError', field: string, errors: Array<string> }> | null } | null } | null };

export type PersonalProfile_ViewerFragment = { __typename?: 'Viewer', name: string, lastName?: string | null, email: string, bio?: string | null, billingAccount: { __typename?: 'BillingAccount', plan: string } };

export type SignUpMutationVariables = Exact<{
  input: SignUpInput;
}>;


export type SignUpMutation = { __typename?: 'Mutation', signUp?: { __typename?: 'SignUpPayload', success?: boolean | null, error?: { __typename?: 'MutationValidationError', message: string, details?: Array<{ __typename?: 'FieldValidationError', field: string, errors: Array<string> }> | null } | null } | null };

export type IndexPageQueryVariables = Exact<{ [key: string]: never; }>;


export type IndexPageQuery = { __typename?: 'Query', viewer?: { __typename?: 'Viewer', name: string, email: string, id: string, billingAccount: { __typename?: 'BillingAccount', plan: string } } | null };

export type ProfilePageQueryVariables = Exact<{ [key: string]: never; }>;


export type ProfilePageQuery = { __typename?: 'Query', viewer?: { __typename?: 'Viewer', name: string, email: string, id: string, lastName?: string | null, bio?: string | null, billingAccount: { __typename?: 'BillingAccount', plan: string } } | null };

export type ProjectPageQueryVariables = Exact<{
  projectId: Scalars['ID'];
}>;


export type ProjectPageQuery = { __typename?: 'Query', project?: { __typename?: 'BillingAccount' } | { __typename?: 'Diagram' } | { __typename?: 'Project', name: string, id: string, canCreateDiagram: string, diagrams?: { __typename?: 'DiagramConnection', totalCount: number, edges?: Array<{ __typename?: 'DiagramEdge', node?: { __typename?: 'Diagram', id: string, name: string, type: string } | null } | null> | null } | null } | null, viewer?: { __typename?: 'Viewer', name: string, email: string, id: string, suppressedNotifications: Array<string | null>, billingAccount: { __typename?: 'BillingAccount', plan: string } } | null };

export type DiagramDetailQueryVariables = Exact<{
  diagramId: Scalars['ID'];
}>;


export type DiagramDetailQuery = { __typename?: 'Query', diagram?: { __typename?: 'BillingAccount' } | { __typename?: 'Diagram', id: string, name: string, content?: string | null, type: string, createdAt: string, createdBy: string, updatedAt: string, updatedBy: string } | { __typename?: 'Project' } | null };

export type UpdateProjectPageQueryVariables = Exact<{
  projectId: Scalars['ID'];
}>;


export type UpdateProjectPageQuery = { __typename?: 'Query', project?: { __typename?: 'BillingAccount' } | { __typename?: 'Diagram' } | { __typename?: 'Project', name: string, id: string } | null, viewer?: { __typename?: 'Viewer', id: string, name: string, lastName?: string | null, email: string, billingAccount: { __typename?: 'BillingAccount', plan: string } } | null };

export type ProjectsPageQueryVariables = Exact<{ [key: string]: never; }>;


export type ProjectsPageQuery = { __typename?: 'Query', viewer?: { __typename?: 'Viewer', canCreateProject: string, id: string, name: string, email: string, projects?: { __typename?: 'ProjectConnection', totalCount: number, edges?: Array<{ __typename?: 'ProjectEdge', node?: { __typename?: 'Project', id: string, name: string, updatedAt: string, diagrams?: { __typename?: 'DiagramConnection', totalCount: number } | null } | null } | null> | null } | null, billingAccount: { __typename?: 'BillingAccount', plan: string } } | null };

export const Navigation_ViewerFragmentDoc = gql`
    fragment Navigation_viewer on Viewer {
  name
  email
}
    `;
export const Layout_ViewerFragmentDoc = gql`
    fragment Layout_viewer on Viewer {
  id
  billingAccount {
    plan
  }
  ...Navigation_viewer
}
    ${Navigation_ViewerFragmentDoc}`;
export const SidebarLayout_ViewerFragmentDoc = gql`
    fragment SidebarLayout_viewer on Viewer {
  name
  email
  ...Layout_viewer
}
    ${Layout_ViewerFragmentDoc}`;
export const DiagramBrowserTree_ProjectFragmentDoc = gql`
    fragment DiagramBrowserTree_project on Project {
  id
  canCreateDiagram
}
    `;
export const DiagramBrowserTree_DiagramConnectionFragmentDoc = gql`
    fragment DiagramBrowserTree_diagramConnection on DiagramConnection {
  edges {
    node {
      id
      name
      type
    }
  }
  totalCount
}
    `;
export const DiagramInfo_DiagramFragmentDoc = gql`
    fragment DiagramInfo_diagram on Diagram {
  id
  name
  type
  createdAt
  createdBy
  updatedAt
  updatedBy
}
    `;
export const SuppressableNotificationDialog_ViewerFragmentDoc = gql`
    fragment SuppressableNotificationDialog_viewer on Viewer {
  suppressedNotifications
}
    `;
export const CreateDiagramTutorial_ViewerFragmentDoc = gql`
    fragment CreateDiagramTutorial_viewer on Viewer {
  ...SuppressableNotificationDialog_viewer
}
    ${SuppressableNotificationDialog_ViewerFragmentDoc}`;
export const ProjectCard_ProjectFragmentDoc = gql`
    fragment ProjectCard_project on Project {
  id
  name
  updatedAt
  diagrams {
    totalCount
  }
}
    `;
export const ProjectList_ProjectConnectionFragmentDoc = gql`
    fragment ProjectList_projectConnection on ProjectConnection {
  edges {
    node {
      ...ProjectCard_project
    }
  }
  totalCount
}
    ${ProjectCard_ProjectFragmentDoc}`;
export const UpdateProject_ProjectFragmentDoc = gql`
    fragment UpdateProject_project on Project {
  id
  name
}
    `;
export const UpdateProject_ViewerFragmentDoc = gql`
    fragment UpdateProject_viewer on Viewer {
  name
  lastName
  email
}
    `;
export const PersonalProfile_ViewerFragmentDoc = gql`
    fragment PersonalProfile_viewer on Viewer {
  name
  lastName
  email
  bio
  billingAccount {
    plan
  }
}
    `;
export const UpdateProfileSettingsDocument = gql`
    mutation UpdateProfileSettings($input: UpdateProfileSettingsInput!) {
  updateProfileSettings(input: $input) {
    viewer {
      id
      suppressedNotifications
    }
  }
}
    `;
export type UpdateProfileSettingsMutationFn = Apollo.MutationFunction<UpdateProfileSettingsMutation, UpdateProfileSettingsMutationVariables>;

/**
 * __useUpdateProfileSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateProfileSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileSettingsMutation, { data, loading, error }] = useUpdateProfileSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProfileSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfileSettingsMutation, UpdateProfileSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProfileSettingsMutation, UpdateProfileSettingsMutationVariables>(UpdateProfileSettingsDocument, options);
      }
export type UpdateProfileSettingsMutationHookResult = ReturnType<typeof useUpdateProfileSettingsMutation>;
export type UpdateProfileSettingsMutationResult = Apollo.MutationResult<UpdateProfileSettingsMutation>;
export type UpdateProfileSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateProfileSettingsMutation, UpdateProfileSettingsMutationVariables>;
export const CreateDiagramDocument = gql`
    mutation CreateDiagram($input: CreateDiagramInput!) {
  createDiagram(input: $input) {
    diagram {
      id
    }
    error {
      message
      code
      details {
        field
        errors
      }
    }
  }
}
    `;
export type CreateDiagramMutationFn = Apollo.MutationFunction<CreateDiagramMutation, CreateDiagramMutationVariables>;

/**
 * __useCreateDiagramMutation__
 *
 * To run a mutation, you first call `useCreateDiagramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDiagramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDiagramMutation, { data, loading, error }] = useCreateDiagramMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDiagramMutation(baseOptions?: Apollo.MutationHookOptions<CreateDiagramMutation, CreateDiagramMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDiagramMutation, CreateDiagramMutationVariables>(CreateDiagramDocument, options);
      }
export type CreateDiagramMutationHookResult = ReturnType<typeof useCreateDiagramMutation>;
export type CreateDiagramMutationResult = Apollo.MutationResult<CreateDiagramMutation>;
export type CreateDiagramMutationOptions = Apollo.BaseMutationOptions<CreateDiagramMutation, CreateDiagramMutationVariables>;
export const UpdateDiagramContentDocument = gql`
    mutation UpdateDiagramContent($input: UpdateDiagramInput!) {
  updateDiagram(input: $input) {
    diagram {
      id
      content
      updatedAt
      updatedBy
    }
    error {
      message
      details {
        field
        errors
      }
    }
  }
}
    `;
export type UpdateDiagramContentMutationFn = Apollo.MutationFunction<UpdateDiagramContentMutation, UpdateDiagramContentMutationVariables>;

/**
 * __useUpdateDiagramContentMutation__
 *
 * To run a mutation, you first call `useUpdateDiagramContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDiagramContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDiagramContentMutation, { data, loading, error }] = useUpdateDiagramContentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDiagramContentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDiagramContentMutation, UpdateDiagramContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDiagramContentMutation, UpdateDiagramContentMutationVariables>(UpdateDiagramContentDocument, options);
      }
export type UpdateDiagramContentMutationHookResult = ReturnType<typeof useUpdateDiagramContentMutation>;
export type UpdateDiagramContentMutationResult = Apollo.MutationResult<UpdateDiagramContentMutation>;
export type UpdateDiagramContentMutationOptions = Apollo.BaseMutationOptions<UpdateDiagramContentMutation, UpdateDiagramContentMutationVariables>;
export const CreateProjectDocument = gql`
    mutation CreateProject($input: CreateProjectInput!) {
  createProject(input: $input) {
    project {
      id
    }
    error {
      message
      code
      details {
        field
        errors
      }
    }
  }
}
    `;
export type CreateProjectMutationFn = Apollo.MutationFunction<CreateProjectMutation, CreateProjectMutationVariables>;

/**
 * __useCreateProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMutation, { data, loading, error }] = useCreateProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProjectMutation(baseOptions?: Apollo.MutationHookOptions<CreateProjectMutation, CreateProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProjectMutation, CreateProjectMutationVariables>(CreateProjectDocument, options);
      }
export type CreateProjectMutationHookResult = ReturnType<typeof useCreateProjectMutation>;
export type CreateProjectMutationResult = Apollo.MutationResult<CreateProjectMutation>;
export type CreateProjectMutationOptions = Apollo.BaseMutationOptions<CreateProjectMutation, CreateProjectMutationVariables>;
export const UpdateProjectDocument = gql`
    mutation UpdateProject($input: UpdateProjectInput!) {
  updateProject(input: $input) {
    project {
      id
      name
    }
    error {
      message
      details {
        field
        errors
      }
    }
  }
}
    `;
export type UpdateProjectMutationFn = Apollo.MutationFunction<UpdateProjectMutation, UpdateProjectMutationVariables>;

/**
 * __useUpdateProjectMutation__
 *
 * To run a mutation, you first call `useUpdateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectMutation, { data, loading, error }] = useUpdateProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectMutation, UpdateProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectMutation, UpdateProjectMutationVariables>(UpdateProjectDocument, options);
      }
export type UpdateProjectMutationHookResult = ReturnType<typeof useUpdateProjectMutation>;
export type UpdateProjectMutationResult = Apollo.MutationResult<UpdateProjectMutation>;
export type UpdateProjectMutationOptions = Apollo.BaseMutationOptions<UpdateProjectMutation, UpdateProjectMutationVariables>;
export const SendMessageDocument = gql`
    mutation SendMessage($input: SendMessageInput!) {
  sendMessage(input: $input) {
    success
    error {
      message
      details {
        field
        errors
      }
    }
  }
}
    `;
export type SendMessageMutationFn = Apollo.MutationFunction<SendMessageMutation, SendMessageMutationVariables>;

/**
 * __useSendMessageMutation__
 *
 * To run a mutation, you first call `useSendMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMessageMutation, { data, loading, error }] = useSendMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendMessageMutation(baseOptions?: Apollo.MutationHookOptions<SendMessageMutation, SendMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendMessageMutation, SendMessageMutationVariables>(SendMessageDocument, options);
      }
export type SendMessageMutationHookResult = ReturnType<typeof useSendMessageMutation>;
export type SendMessageMutationResult = Apollo.MutationResult<SendMessageMutation>;
export type SendMessageMutationOptions = Apollo.BaseMutationOptions<SendMessageMutation, SendMessageMutationVariables>;
export const UpdatePasswordDocument = gql`
    mutation UpdatePassword($input: UpdatePasswordInput!) {
  updatePassword(input: $input) {
    success
    error {
      message
      details {
        field
        errors
      }
    }
  }
}
    `;
export type UpdatePasswordMutationFn = Apollo.MutationFunction<UpdatePasswordMutation, UpdatePasswordMutationVariables>;

/**
 * __useUpdatePasswordMutation__
 *
 * To run a mutation, you first call `useUpdatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePasswordMutation, { data, loading, error }] = useUpdatePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePasswordMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePasswordMutation, UpdatePasswordMutationVariables>(UpdatePasswordDocument, options);
      }
export type UpdatePasswordMutationHookResult = ReturnType<typeof useUpdatePasswordMutation>;
export type UpdatePasswordMutationResult = Apollo.MutationResult<UpdatePasswordMutation>;
export type UpdatePasswordMutationOptions = Apollo.BaseMutationOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>;
export const UpdatePersonalProfileDocument = gql`
    mutation UpdatePersonalProfile($input: UpdatePersonalProfileInput!) {
  updatePersonalProfile(input: $input) {
    viewer {
      name
      lastName
      email
      bio
    }
    error {
      message
      details {
        field
        errors
      }
    }
  }
}
    `;
export type UpdatePersonalProfileMutationFn = Apollo.MutationFunction<UpdatePersonalProfileMutation, UpdatePersonalProfileMutationVariables>;

/**
 * __useUpdatePersonalProfileMutation__
 *
 * To run a mutation, you first call `useUpdatePersonalProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePersonalProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePersonalProfileMutation, { data, loading, error }] = useUpdatePersonalProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePersonalProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePersonalProfileMutation, UpdatePersonalProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePersonalProfileMutation, UpdatePersonalProfileMutationVariables>(UpdatePersonalProfileDocument, options);
      }
export type UpdatePersonalProfileMutationHookResult = ReturnType<typeof useUpdatePersonalProfileMutation>;
export type UpdatePersonalProfileMutationResult = Apollo.MutationResult<UpdatePersonalProfileMutation>;
export type UpdatePersonalProfileMutationOptions = Apollo.BaseMutationOptions<UpdatePersonalProfileMutation, UpdatePersonalProfileMutationVariables>;
export const SignUpDocument = gql`
    mutation SignUp($input: SignUpInput!) {
  signUp(input: $input) {
    success
    error {
      message
      details {
        field
        errors
      }
    }
  }
}
    `;
export type SignUpMutationFn = Apollo.MutationFunction<SignUpMutation, SignUpMutationVariables>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignUpMutation(baseOptions?: Apollo.MutationHookOptions<SignUpMutation, SignUpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(SignUpDocument, options);
      }
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<SignUpMutation, SignUpMutationVariables>;
export const IndexPageDocument = gql`
    query IndexPage {
  viewer {
    name
    email
    ...Layout_viewer
  }
}
    ${Layout_ViewerFragmentDoc}`;

/**
 * __useIndexPageQuery__
 *
 * To run a query within a React component, call `useIndexPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndexPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndexPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useIndexPageQuery(baseOptions?: Apollo.QueryHookOptions<IndexPageQuery, IndexPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IndexPageQuery, IndexPageQueryVariables>(IndexPageDocument, options);
      }
export function useIndexPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IndexPageQuery, IndexPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IndexPageQuery, IndexPageQueryVariables>(IndexPageDocument, options);
        }
export type IndexPageQueryHookResult = ReturnType<typeof useIndexPageQuery>;
export type IndexPageLazyQueryHookResult = ReturnType<typeof useIndexPageLazyQuery>;
export type IndexPageQueryResult = Apollo.QueryResult<IndexPageQuery, IndexPageQueryVariables>;
export const ProfilePageDocument = gql`
    query ProfilePage {
  viewer {
    name
    email
    ...Layout_viewer
    ...PersonalProfile_viewer
  }
}
    ${Layout_ViewerFragmentDoc}
${PersonalProfile_ViewerFragmentDoc}`;

/**
 * __useProfilePageQuery__
 *
 * To run a query within a React component, call `useProfilePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfilePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfilePageQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfilePageQuery(baseOptions?: Apollo.QueryHookOptions<ProfilePageQuery, ProfilePageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProfilePageQuery, ProfilePageQueryVariables>(ProfilePageDocument, options);
      }
export function useProfilePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfilePageQuery, ProfilePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProfilePageQuery, ProfilePageQueryVariables>(ProfilePageDocument, options);
        }
export type ProfilePageQueryHookResult = ReturnType<typeof useProfilePageQuery>;
export type ProfilePageLazyQueryHookResult = ReturnType<typeof useProfilePageLazyQuery>;
export type ProfilePageQueryResult = Apollo.QueryResult<ProfilePageQuery, ProfilePageQueryVariables>;
export const ProjectPageDocument = gql`
    query ProjectPage($projectId: ID!) {
  project: node(id: $projectId) {
    ... on Project {
      name
      ...DiagramBrowserTree_project
      diagrams {
        ...DiagramBrowserTree_diagramConnection
      }
    }
  }
  viewer {
    ...SidebarLayout_viewer
    ...CreateDiagramTutorial_viewer
  }
}
    ${DiagramBrowserTree_ProjectFragmentDoc}
${DiagramBrowserTree_DiagramConnectionFragmentDoc}
${SidebarLayout_ViewerFragmentDoc}
${CreateDiagramTutorial_ViewerFragmentDoc}`;

/**
 * __useProjectPageQuery__
 *
 * To run a query within a React component, call `useProjectPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectPageQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectPageQuery(baseOptions: Apollo.QueryHookOptions<ProjectPageQuery, ProjectPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectPageQuery, ProjectPageQueryVariables>(ProjectPageDocument, options);
      }
export function useProjectPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectPageQuery, ProjectPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectPageQuery, ProjectPageQueryVariables>(ProjectPageDocument, options);
        }
export type ProjectPageQueryHookResult = ReturnType<typeof useProjectPageQuery>;
export type ProjectPageLazyQueryHookResult = ReturnType<typeof useProjectPageLazyQuery>;
export type ProjectPageQueryResult = Apollo.QueryResult<ProjectPageQuery, ProjectPageQueryVariables>;
export const DiagramDetailDocument = gql`
    query DiagramDetail($diagramId: ID!) {
  diagram: node(id: $diagramId) {
    ... on Diagram {
      id
      name
      content
      type
      ...DiagramInfo_diagram
    }
  }
}
    ${DiagramInfo_DiagramFragmentDoc}`;

/**
 * __useDiagramDetailQuery__
 *
 * To run a query within a React component, call `useDiagramDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiagramDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiagramDetailQuery({
 *   variables: {
 *      diagramId: // value for 'diagramId'
 *   },
 * });
 */
export function useDiagramDetailQuery(baseOptions: Apollo.QueryHookOptions<DiagramDetailQuery, DiagramDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DiagramDetailQuery, DiagramDetailQueryVariables>(DiagramDetailDocument, options);
      }
export function useDiagramDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DiagramDetailQuery, DiagramDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DiagramDetailQuery, DiagramDetailQueryVariables>(DiagramDetailDocument, options);
        }
export type DiagramDetailQueryHookResult = ReturnType<typeof useDiagramDetailQuery>;
export type DiagramDetailLazyQueryHookResult = ReturnType<typeof useDiagramDetailLazyQuery>;
export type DiagramDetailQueryResult = Apollo.QueryResult<DiagramDetailQuery, DiagramDetailQueryVariables>;
export const UpdateProjectPageDocument = gql`
    query UpdateProjectPage($projectId: ID!) {
  project: node(id: $projectId) {
    ... on Project {
      name
      ...UpdateProject_project
    }
  }
  viewer {
    ...Layout_viewer
    ...UpdateProject_viewer
  }
}
    ${UpdateProject_ProjectFragmentDoc}
${Layout_ViewerFragmentDoc}
${UpdateProject_ViewerFragmentDoc}`;

/**
 * __useUpdateProjectPageQuery__
 *
 * To run a query within a React component, call `useUpdateProjectPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateProjectPageQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useUpdateProjectPageQuery(baseOptions: Apollo.QueryHookOptions<UpdateProjectPageQuery, UpdateProjectPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UpdateProjectPageQuery, UpdateProjectPageQueryVariables>(UpdateProjectPageDocument, options);
      }
export function useUpdateProjectPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UpdateProjectPageQuery, UpdateProjectPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UpdateProjectPageQuery, UpdateProjectPageQueryVariables>(UpdateProjectPageDocument, options);
        }
export type UpdateProjectPageQueryHookResult = ReturnType<typeof useUpdateProjectPageQuery>;
export type UpdateProjectPageLazyQueryHookResult = ReturnType<typeof useUpdateProjectPageLazyQuery>;
export type UpdateProjectPageQueryResult = Apollo.QueryResult<UpdateProjectPageQuery, UpdateProjectPageQueryVariables>;
export const ProjectsPageDocument = gql`
    query ProjectsPage {
  viewer {
    canCreateProject
    ...Layout_viewer
    projects {
      ...ProjectList_projectConnection
      totalCount
    }
  }
}
    ${Layout_ViewerFragmentDoc}
${ProjectList_ProjectConnectionFragmentDoc}`;

/**
 * __useProjectsPageQuery__
 *
 * To run a query within a React component, call `useProjectsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useProjectsPageQuery(baseOptions?: Apollo.QueryHookOptions<ProjectsPageQuery, ProjectsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectsPageQuery, ProjectsPageQueryVariables>(ProjectsPageDocument, options);
      }
export function useProjectsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectsPageQuery, ProjectsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectsPageQuery, ProjectsPageQueryVariables>(ProjectsPageDocument, options);
        }
export type ProjectsPageQueryHookResult = ReturnType<typeof useProjectsPageQuery>;
export type ProjectsPageLazyQueryHookResult = ReturnType<typeof useProjectsPageLazyQuery>;
export type ProjectsPageQueryResult = Apollo.QueryResult<ProjectsPageQuery, ProjectsPageQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Node": [
      "BillingAccount",
      "Diagram",
      "Project"
    ]
  }
};
      export default result;
    