import { gql } from "@apollo/client";
import { Layout_ViewerFragment } from "generated/graphql";
import { signOut } from "next-auth/react";
import { useRouter } from "next/router";
import { Button } from "primereact/button";
import { SplitButton } from 'primereact/splitbutton';
import { useState } from "react";

type Props = {
  viewer?: Layout_ViewerFragment,
  openLogInDialog?: () => void,
  openSignUpDialog?: () => void,
}

const UserIdentity = ({ name }: { name: string }) => {
  const router = useRouter();
  const items = [
    {
      label: 'Sign out',
      icon: 'pi pi-sign-out',
      command: (e) => {
        signOut({ callbackUrl: '/' });
      }
    },
  ];
  return <SplitButton label={name} icon="pi pi-user" onClick={() => router.push('/profile')} model={items} className="p-button-text" />
};

const Navigation = (props: Props) => {
  const router = useRouter();
  return (<>
    <div className="layout-topbar flex align-items-center justify-content-between">
      <div className="layout-topbar-left">
        <a href="/">
          <img src="/static/img/logo_transparent.png" alt="Dopredu" id="topbar-logo" height={50} />
        </a>
      </div>
      {props.viewer && <>
        <div className="flex-grow-1 mx-5">
          <Button label="Projects" onClick={() => router.push('/projects')} className="p-button-text mr-2" />
        </div>
        <UserIdentity name={props.viewer.name} />
      </>}
      {!props.viewer && props.openLogInDialog && props.openSignUpDialog && <div className="layout-topbar-right">
        <Button label="Log in" onClick={props.openLogInDialog} className="p-button-secondary p-button-text mr-2" />
        <Button label="Sign up" onClick={props.openSignUpDialog} />
      </div>}
    </div>
  </>);
}

Navigation.fragments = {
  viewer: gql`
    fragment Navigation_viewer on Viewer {
      name
      email
    }`
};

export default Navigation;

