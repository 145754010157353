const DEFAULT_TITLE = 'Dopredu';
const DEFAULT_TITLE_SHORT = DEFAULT_TITLE;

export const META_IMAGE = `${process.env.NEXT_PUBLIC_BASE_URL}/static/img/logo_transparent.png`;
export const META_DESCRIPTION = 'Modeling and design for modern IT architects, analysts, and developers'


export const headUrl = (url?: string) => {
  if (url)
    return `${process.env.NEXT_PUBLIC_BASE_URL}/${url}`;
  else
    return process.env.NEXT_PUBLIC_BASE_URL;
}

export const headTitle = (title?: string) => {
  if (title)
    return `${title} | ${DEFAULT_TITLE_SHORT}`;
  else
    return DEFAULT_TITLE;
}
