// log the pageview with their URL
export const gtagPageview = (url) => {
  if (!process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID) return;
  //@ts-ignore
  window.gtag('config', process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID, {
    page_path: url,
  });
}

// log specific events happening.
export const gtagEvent = (name, params?) => {
  if (!process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID) return;
  //@ts-ignore
  window.gtag('event', name, params);
}
