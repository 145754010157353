import React from 'react';
import Head from 'next/head';
import Navigation from './Navigation';
import { gql } from '@apollo/client';
import { Layout_ViewerFragment } from 'generated/graphql';
import { classNames } from 'primereact/utils';
import { headTitle, headUrl, META_DESCRIPTION, META_IMAGE } from './utils';
import Script from 'next/script';
import { ConfirmDialog } from 'primereact/confirmdialog';

export type Props = React.PropsWithChildren<{
  viewer?: Layout_ViewerFragment,
  openLogInDialog?: () => void,
  openSignUpDialog?: () => void,
  navigation?: boolean,
  marginless?: boolean,
  title?: string,
  url?: string,
}>;

const Layout = ({ viewer, openLogInDialog, openSignUpDialog, navigation = true, marginless, title, url, children }: Props) => (
  <>
    <Head>
      <meta charSet='utf-8' />
      <title>{headTitle(title)}</title>
      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name='viewport' content='initial-scale=1.0, width=device-width' />
      <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&amp;display=swap" rel="stylesheet" />
      <link rel="preconnect" href="https://fonts.gstatic.com/" crossOrigin="anonymous" />
      <link rel="preconnect" href="https://www.google-analytics.com" crossOrigin="anonymous" />
      <meta name="color-scheme" content="light" />
      <link rel="icon" type="image/x-icon" href="/favicon.ico" />
      <meta key="og:title" property="og:title" content={headTitle(title)} />
      <meta key="og:type" property="og:type" content="website" />
      <meta key="og:image" property="og:image" content={META_IMAGE} />
      <meta key="og:description" property="og:description" content={META_DESCRIPTION} />
      {url && <meta key="og:url" property="og:url" content={headUrl(url)} />}
      <meta key="twitter:card" property="twitter:card" content="summary_large_image" />
      <meta key="twitter:title" property="twitter:title" content={headTitle(title)} />
      <meta key="twitter:description" property="twitter:description" content={META_DESCRIPTION} />
      <meta key="twitter:image" property="twitter:image" content={META_IMAGE} />
      <meta name="description" content={META_DESCRIPTION} />
    </Head>
    {process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID && <>
      <Script src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}`} strategy="afterInteractive" />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}');
          ${viewer ? `
            gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}', {'user_id': '${viewer.id}' });
            gtag('set', 'user_properties', { 'viewer_id' : '${viewer.id}', 'plan': '${viewer.billingAccount.plan}' });
          ` : ''}
        `}
      </Script>
    </>}
    {process.env.NODE_ENV == 'production' && <Script id="ms-clarity" strategy="afterInteractive">{`(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "c74eq21hxg");`}</Script>}
    <div>
      {navigation && <Navigation viewer={viewer} openLogInDialog={openLogInDialog} openSignUpDialog={openSignUpDialog} />}
      <div className={classNames("row flex-grow-1", { 'm-5': !marginless })}>
        <main>
          {children}
          <ConfirmDialog />
        </main>
      </div>
    </div>
  </>
);

Layout.fragments = {
  viewer: gql`
    fragment Layout_viewer on Viewer {
      id
      billingAccount {
        plan
      }
      ...Navigation_viewer
    }
    ${Navigation.fragments.viewer}
    `
};


export default Layout;
